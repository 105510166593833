
const SNUSER = {
	onReady: () => {
        SNUSER.initAskDeleteUserModal();
        SNUSER.initUserNotLoggedInModal();
	},
				
				
				
    initAskDeleteUserModal: () => {
        const triggerAskDeleteUser = document.querySelectorAll('.trigger-ask-profile-delete');

        triggerAskDeleteUser.forEach( (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                let type = trigger.dataset.modalType || 'info';
                let title = trigger.dataset.modalTitle || trigger.innerHTML;
                let body = trigger.dataset.modalBody || '';
                let button = trigger.dataset.modalButton || 'OK';
                
                Bulma().alert({
                    type: type,
                    title: title,
                    body: body,
                    confirm: {
                        label: button,
                        onClick: function() {
                            SNUSER.context = trigger.parentElement;
                            SNUSER.askDeleteUser();
                        },
                    },
                    cancel: { 
                        label: 'Abbrechen' 
                    }
                });
            });
        });
    },



	askDeleteUser: () => {
		const { action, method } = SNUSER.context;
		const body = new FormData(SNUSER.context);

		fetch(action, {
            method,
			body,
			credentials: "same-origin",
			headers: {
		      "X-WP-Nonce": snJS.nonce,
		    },
		})
		.then((response) => response.json())
		.then((response) => {
			console.log(response);

            Bulma().alert({
                type: 'info',
                title: 'Profil löschen',
                body: 'Wir haben Ihre Anfrage erhalten. Sie werden nun abgemeldet.',
                confirm: {
                    label: 'Ok'
                }
            });
       
            setTimeout( () => {
                document.location.replace(snJS.logoutUrl);
                
            }, 5000 );

		})
		.catch((error) => {
            Bulma().alert({
                type: 'danger',
                title: 'Fehler',
                body: 'Bei Ihrer Anfrage ist eine Fehler aufgetreten. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.',
                confirm: {
                    label: 'OK',
                }
            });
		})
		;
	},



    initUserNotLoggedInModal: () => {
        const triggerUserNotLoggedIn = document.querySelectorAll('.trigger-user-not-logged-in');

        triggerUserNotLoggedIn.forEach( (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                let title    = trigger.dataset.modalTitle || trigger.innerHTML;
                let body     = trigger.dataset.modalBody || '';
                let register = trigger.dataset.modalRegister || 'false';
                let login    = trigger.dataset.modalLogin || 'false';
                let buttons  = [];

                if ( login != 'false' ) {
                    const fetchBody = new FormData();
                    fetchBody.append("tpl", 'form-login');

                    fetch(snJS.restUrl + '/template/locate', {
                        method: 'POST',
                        fetchBody,
                        credentials: "same-origin",
                        headers: {
                          "X-WP-Nonce": snJS.nonce,
                        },
                    })
                    .then(function(response) {
                        return response.text()
                    })
                    .then(function(html) {
                        // console.log(html);
                        body += html;

                        Bulma().modal({
                            title: title,
                            body: body,
                            buttons: buttons
                        }).open( () => {
                        
                        
                        
                        });
                        document.querySelector('input[name=redirect_to]').setAttribute('value', window.location.href);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                } else {
                    Bulma().modal({
                            title: title,
                            body: body,
                            buttons: buttons
                        }).open();
                }

            });
        });
    },
}



SNUSER.onReady();